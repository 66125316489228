<template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item name="1">
        <template slot="title">
          <div style="font-size: 16px">软件简介</div>
        </template>
        <div><br>
          <el-image
              style="width: 50%;"
              src="../src/sanlly.png">
          </el-image>
        </div>
        <div>杉谷科技&科立华智能科技安全AI分析系统是一款基于人工智能技术的安全分析工具，旨在帮助用户识别和分析潜在的安全风险。</div>
        <br>
        <div>该系统支持多种类型的安全事件识别，包括：</div>
        <div>摔倒检测：该任务可用于检测监控区域内的人员是否发生了摔倒事件。</div>
        <div>明火检测：该任务可用于检测监控区域内是否出现明火，如火灾等。</div>
        <div>烟雾检测：该任务可用于检测监控区域内是否出现烟雾，如火灾等。</div>
        <div>污水排放检测：该任务可用于检测监控区域内是否出现排放污水等违法行为。</div>
        <div>.....</div>
        <br>
        <div>我们希望本系统能够为您提供高效、准确的监控和报警服务，保障您的人员和财产的安全。如有任何问题和建议，请随时联系系统管理员。</div>
      </el-collapse-item>
      <el-collapse-item>
        <template slot="title">
          <div style="font-size: 16px">登录</div>
        </template>
        <div>1. 浏览器访问 https://www.ipu-cloud.com/login</div>
        <div>2. 输入管理员分配的账号密码</div>
        <div>3. 点击登录</div>
        <br>
        <div>
          <el-image
              style="width: 300px; height: 200px"
              :src="srcListLogin[0]"
              :preview-src-list="srcListLogin">
          </el-image>
        </div>
      </el-collapse-item>
      <el-collapse-item title="">
        <template slot="title">
          <div style="font-size: 16px">首页</div>
        </template>
        <div>首页菜单栏拥有 事件报警 事件管理 数据统计我的工作台等跳转标签：</div>
        <br>
        <div>
          <el-image
              style="width: 800px; height: 80px"
              :src="srcListHome[0]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
        <div>1. 点击事件报警拉起首页右侧报警信息列表，首页仅展示最近100条报警信息，更多信息请前往事件关系查看详情。</div>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[1]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
        <div>(1) 点击报警信息展开操作详情</div>
        <div>(2) 输入解除报警理由，选择解除报警事件，点击解除报警即可解除该报警信息</div>
        <br>
        <div>
          <el-image
              style="width: 400px; height: 300px"
              :src="srcListHome[2]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>(3) 点击报警详情，可在首页左侧拉起该报警的媒体信息，如报警视频、报警图片...</div>
        <br>
        <div>
          <el-image
              style="width: 400px; height: 700px"
              :src="srcListHome[3]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>

        <div>2. 点击事件管理跳转工作台报警信息列表详情页，工作台报警信息列表包含全部报警信息。</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[4]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>3. 点击数据统计跳转工作台设备数据详情页</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[5]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>4. 点击我的工作台进入工作台欢迎页面</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[6]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
        <div>首页右侧常驻设备状态信息 报警数量统计：</div>
        <br>
        <div>
          <el-image
              style="width: 300px; height: 600px"
              :src="srcListHome[7]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>1. 点击分析点位跳转工作台设备数据详情页</div>
        <br>
        <div>
          <el-image
              style="width: 300px; height: 100px"
              :src="srcListHome[8]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>2. 点击数量统计跳转工作台报警信息列表详情页</div>
        <br>
        <div>
          <el-image
              style="width: 300px; height: 300px"
              :src="srcListHome[9]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
        <div>存在未处理报警信息时 首页中心会亮起报警提醒：</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 100px"
              :src="srcListHome[10]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
        <div>1. 点击报警提醒拉起首页右侧报警信息列表，首页仅展示最近100条报警信息，更多信息请前往事件关系查看详情。</div>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[1]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <br>
      </el-collapse-item>
      <el-collapse-item title="我的工作台">
        <template slot="title">
          <div style="font-size: 16px">我的工作台</div>
        </template>
        <div>我的工作台右侧菜单栏拥有首页 数据统计 事件管理 帮助等跳转标签：</div>
        <div><br>
          <el-image
              style="width: 300px; height: 500px"
              :src="srcListWork[0]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>1. 点击首页跳转安全AI报警系统首页</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[11]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>2. 点击数据统计跳转设备数据详情页</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[4]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>(1) 统计状态信息 报警数量统计</div>
        <div><br>
          <el-image
              style="width: 300px; height: 380px"
              :src="srcListWork[1]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(2) 展示集群状态信息</div>
        <div><br>
          <el-image
              style="width: 300px; height: 350px"
              :src="srcListWork[2]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(3) 展示各种不同类型的报警统计表</div>
        <div><br>
          <el-image
              style="width: 500px; height: 200px"
              :src="srcListWork[3]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(4) 展示不同报警点位在不同时段发生报警的散点图</div>
        <div><br>
          <el-image
              style="width: 300px; height: 200px"
              :src="srcListWork[4]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>3. 点击事件管理跳转报警信息列表详情页，工作台报警信息列表包含全部报警信息。</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[5]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>(1) 提供便捷条件查询选项卡</div>
        <div><br>
          <el-image
              style="width: 400px; height: 100px"
              :src="srcListWork[5]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(2) 根据条件查询后展示报警信息列表</div>
        <div><br>
          <el-image
              style="width: 400px; height: 700px"
              :src="srcListWork[6]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(3) 点击报警信息上的删除、解除按钮可以报警信息进行处理</div>
        <div><br>
          <el-image
              style="width: 400px; height: 100px"
              :src="srcListWork[7]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(4) 点击报警信息，右侧同步加载信息相关的详细数据</div>
        <div><br>
          <el-image
              style="width: 450px; height: 400px"
              :src="srcListWork[8]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>4. 点击帮助跳转帮助页面</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListWork[13]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>(1) 查看使用说明书</div>
        <div>(2) 如果您有更好的修改意见，请您不吝赐教，将建议发送给我们，不胜感激。</div>
        <br>
        <div>我的工作台顶部菜单栏拥有首页 警告 用户头像等点击标签</div>
        <div><br>
          <el-image
              style="width: 500px; height: 100px"
              :src="srcListWork[9]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <br>
        <div>1. 点击首页跳转安全AI报警系统首页</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListHome[11]"
              :preview-src-list="srcListHome">
          </el-image>
        </div>
        <div>2. 存在未处理报警信息时 我的工作台顶部菜单栏会亮起报警提醒</div>
        <div><br>
          <el-image
              style="width: 500px; height: 100px"
              :src="srcListWork[9]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <div>(1) 点击顶部菜单栏的报警提醒跳转安全AI报警系统首页并拉起右侧报警列表</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListWork[10]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <div>3. 点击用户头像弹出选项卡 后台管理 退出</div>
        <br>
        <div>
          <el-image
              style="width: 200px; height: 200px"
              :src="srcListWork[11]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <div>(1) 点击后台管理进入项目后台数据管理页面</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListWork[12]"
              :preview-src-list="srcListWork">
          </el-image>
        </div>
        <div>(2) 点击退出，清除用户信息并退回登录页面</div>
      </el-collapse-item>
      <el-collapse-item title="后台管理系统">
        <template slot="title">
          <div style="font-size: 16px">后台管理系统</div>
        </template>
        <div>后台管理系统右侧菜单栏拥有处理日志 工厂 报警信息 报警推送 用户 职级 设备信息 等跳转标签：</div>
        <br>
        <div>
          <el-image
              style="width: 400px; height: 300px"
              :src="srcListAdmin[0]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>1. 点击处理日志跳转处理日志详情页</div>
        <br>
        <div>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[1]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了用户对报警信息所进行的操作处理</div>
        <div>(2) 对操作记录数据进行修改删除</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[2]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>2. 点击工厂跳转工厂详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[3]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了加入安全AI实时分析系统的厂区</div>
        <div>(2) 对厂区数据进行增加修改删除</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[4]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>3. 点击报警信息跳转报警信息详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[5]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了安全AI实时分析系统上报的所有报警信息</div>
        <div>(2) 对报警信息数据进行增加修改删除</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[6]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>4. 点击用户推送跳转用户推送详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[7]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了安全AI实时分析系统可以分析的报警类型，该报警类型的事件等级，以及发生报警时同步通知到那些用户群体</div>
        <div>(2) 对报警类型数据进行增加修改删除</div>
        <div>(3) 调整发生该报警时接收通知的人群</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[8]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>5. 点击用户跳转用户详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[9]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了创建的用户账户列表</div>
        <div>(2) 对用户数据进行增加修改删除</div>
        <div>(3) 调整该用户所属的职位</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[10]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>6. 点击职级跳转职级详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[11]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了创建的用户职级列表</div>
        <div>(2) 对职级数据进行增加修改删除</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[12]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>7. 点击设备信息跳转设备信息详情页</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[13]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
        <div>(1) 记录了用户用于接收通知栏消息的设备信息</div>
        <div>(2) 对设备信息数据进行增加修改删除</div>
        <div><br>
          <el-image
              style="width: 500px; height: 300px"
              :src="srcListAdmin[14]"
              :preview-src-list="srcListAdmin">
          </el-image>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "HelpDoc",
  data() {
    return {
      activeName: '1',
      srcListLogin: [
        'src/help-imgs/login.png',
      ],
      srcListHome: [
        'src/help-imgs/home-header.png',
        'src/help-imgs/home-list.png',
        'src/help-imgs/home-list-connect.png',
        'src/help-imgs/home-detail.png',
        'src/help-imgs/home-data.png',
        'src/help-imgs/home-warn.png',
        'src/help-imgs/home-huanying.png',
        'src/help-imgs/home-data-left.png',
        'src/help-imgs/home-fenxi.png',
        'src/help-imgs/home-datatotle.png',
        'src/help-imgs/home-warnning.png',
        'src/help-imgs/home.png',
      ],
      srcListWork: [
        'src/help-imgs/work-sidbar.png',
        'src/help-imgs/work-data-tongji.png',
        'src/help-imgs/work-jiqun.png',
        'src/help-imgs/work-chart.png',
        'src/help-imgs/work-lisan.png',
        'src/help-imgs/work-xuanxiangka.png',
        'src/help-imgs/work-list.png',
        'src/help-imgs/work-list-one.png',
        'src/help-imgs/work-detail.png',
        'src/help-imgs/work-header.png',
        'src/help-imgs/work-right.png',
        'src/help-imgs/work-user.png',
        'src/help-imgs/admin.png',
        'src/help-imgs/help.png',
      ],
      srcListAdmin: [
        'src/help-imgs/admin-sidbar.png',
        'src/help-imgs/admin-chulirizhi.png',
        'src/help-imgs/admin-chulirizhi-add.png',
        'src/help-imgs/admin-gongchang.png',
        'src/help-imgs/admin-gongchang-add.png',
        'src/help-imgs/admin-warnning.png',
        'src/help-imgs/admin-warnning-add.png',
        'src/help-imgs/admin-leixing.png',
        'src/help-imgs/admin-leixing-add.png',
        'src/help-imgs/admin-yonghu.png',
        'src/help-imgs/admin-yonghu-add.png',
        'src/help-imgs/admin-zhiji.png',
        'src/help-imgs/admin-zhiji-add.png',
        'src/help-imgs/admin-shebei.png',
        'src/help-imgs/admin-shebei-add.png',
      ],
    }
  }
}
</script>

<style scoped>

</style>