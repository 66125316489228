<template>
  <div>
    <div style="font-size: 13px">
      如果您有更好的修改意见，请您不吝赐教，将建议发送给我们，不胜感激。
    </div>
    <br>
    <el-row :gutter="20">
      <el-col span="18">
        <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入您的宝贵意见..."
            v-model="textarea">
        </el-input>
      </el-col>
      <el-col span="6">
        <el-button type="primary" style="margin-top: 92px">联系我们</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "HelpConnect",
  data() {
    return {
      textarea: ''
    }
  }
}
</script>

<style scoped>

</style>