<template>
  <el-row :gutter="20">
    <el-col :span="16">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>使用说明书 v1.0</span>
          <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i></el-button>
        </div>
        <HelpDoc/>
      </el-card>

    </el-col>
    <el-col :span="8">
      <el-card class="box-card-about">
        <div slot="header" class="clearfix">
          <span>关于我们</span>
          <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i></el-button>
        </div>
        <div>
          <div style="font-size: 13px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杉谷科技&科立华智能科技一直致力于通过人工智能技术解决现实世界的安全难题，特别是在视频监控领域的应用上取得了卓越的成绩。公司的主要产品包括智能视频分析系统、智能安防监控系统，这些系统均基于深度学习和计算机视觉技术开发而成，能够自动识别并报警各类异常情况，例如人员摔倒、明火、烟雾、玩手机、排放污水等。
          </div>
          <br>
          <div style="font-size: 13px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在视频监控领域的技术创新和应用。杉谷科技通过多年的技术积累和不断创新，打造了一系列具有自主知识产权的人工智能算法和系统，取得了在视频分析领域的技术领先地位，得到了广泛的市场认可和用户好评。
            在智能安防领域的市场份额和业绩增长。杉谷科技的智能安防产品在国内外市场上受到了广泛的认可和追捧，已经成为众多企业和政府机构的首选品牌。公司的业绩不断增长，取得了很高的市场份额和业绩增长率。
            在人工智能领域的技术贡献。杉谷科技在人工智能技术的研究和应用方面做出了杰出的贡献，多次获得了国内外各种科技奖项和认可，公司成为国内人工智能领域的佼佼者之一。
          </div>
          <br>
          <div style="font-size: 13px"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杉谷科技&科立华智能科技作为一家专注于人工智能安全防控的企业，不断在技术创新、产品研发和市场拓展方面取得了卓越的成绩，为行业的发展做出了重要的贡献。</div>
        </div>
      </el-card>
      <el-card class="box-card-connect" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <span>联系我们</span>
          <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i></el-button>
        </div>
        <HelpConnect/>
      </el-card>

    </el-col>
  </el-row>
</template>


<script>
import HelpDoc from "./HelpDoc.vue"
import HelpConnect from "./HelpConnect.vue"

export default {
  name: "Help",
  components: {
    HelpDoc,
    HelpConnect,
  },
}
</script>
<style scoped>
.box-card {
  height: 85vh;
}
.box-card-about {
  height: 44vh;
}
.box-card-connect {
  height: 40vh;
}
</style>